import React, { FC } from 'react';

import { RouteName, RoutePath } from '../../../config/route-config';
import { AppName } from '../../../config/app-config';

interface Props {
  pathname: string;
}

const TitleText = ({ pathname }: Props) => {
  if (pathname.startsWith(RoutePath.ManualPO)) {
    return <>{RouteName.ManualPO}</>;
  }

  if (pathname.startsWith(RoutePath.Configurations)) {
    return <>{RouteName.Configurations}</>;
  }

  if (pathname.startsWith(RoutePath.Payment)) {
    return <>{RouteName.Payment}</>;
  }

  if (pathname.startsWith(RoutePath.DailyActivityPlanner)) {
    return <>{RouteName.DailyActivityPlanner}</>;
  }

  if (pathname.startsWith(RoutePath.Reports)) {
    return <>{RouteName.Reports}</>;
  }
  if (pathname.startsWith(RoutePath.DOF)) {
    return <>{RouteName.DOF}</>;
  }

  return <>{AppName.RacPad}</>;
};

export const Title: FC<Props> = (props: Props) => {
  return <TitleText {...props} />;
};
