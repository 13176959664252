import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { Aside } from '../../layout/aside/Aside';
import { Header } from '../../layout/header/Header';
import { ScrollTo } from '../../layout/ScrollTo';
import { useGlobalStateContext } from '../../../context/global/global-context';
import { AllowedRoute, getAllowedRoutes } from './routes/getAllowedRoutes';
import { RoutesMapper } from './routes/RoutesMapper';
import { NoUser } from './routes/NoPermissions';
import { StaticContent } from '../../../config/static-content-config';

import styles from './Authenticated.module.scss';
import appConfig from '../../../config/app-config';
import { useFullscreen } from '../../../utils/useFullscreen';

export const authenticatedAppTestId = 'authenticatedAppTestId';

export const Authenticated = withRouter(({ history }) => {
  const isFullscreen = useFullscreen();
  const hasCollapsedChosen =
    sessionStorage.getItem('hasCollapsedChosen') === 'true';

  const [isCollapsed, setIsCollapsed] = React.useState(
    hasCollapsedChosen || false
  );

  const handleSidebarCollapsed = () => {
    setIsCollapsed(!isCollapsed);
    sessionStorage.setItem('hasCollapsedChosen', `${!isCollapsed}`);
  };

  useEffect(() => {
    const rootDiv = document?.getElementById('root');
    if (isCollapsed) {
      rootDiv?.setAttribute('collapsed-sidebar', '');
    } else {
      rootDiv?.removeAttribute('collapsed-sidebar');
    }
  }, [isCollapsed]);

  const {
    currentUser: { user, error: userError }
  } = useGlobalStateContext();

  const allowedRoutes: AllowedRoute[] | undefined = user
    ? getAllowedRoutes(user.permissions?.NAV_MENU || [])
    : undefined;

  useEffect(
    function addGoogleAnalytics() {
      ReactGA.initialize(appConfig.googleAnalytics.trackingId, {
        ...appConfig.googleAnalytics.options,
        testMode: process.env.NODE_ENV === 'test'
      });

      return history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    },
    [] // eslint-disable-line
  );

  useEffect(
    function setGoogleAnalyticsUser() {
      if (!user) {
        return;
      }

      ReactGA.set({
        userId: user.id
      });
    },
    [user?.id] // eslint-disable-line
  );

  return useMemo(
    () => (
      <div data-testid={authenticatedAppTestId}>
        <ScrollTo verticalAxis={0} />
        <div className={clsx(!isFullscreen && styles.pageContentWrapper)}>
          {!isFullscreen && (
            <Aside
              allowedRoutes={allowedRoutes}
              handleSidebarCollapsed={handleSidebarCollapsed}
              isCollapsed={isCollapsed}
            />
          )}
          <div
            className={clsx([
              !isFullscreen && styles.appContainer,
              isCollapsed && styles.lessPadding
            ])}
          >
            {!isFullscreen && <Header isCollapsed={isCollapsed} />}
            <main className={styles.main}>
              {userError ? (
                <NoUser
                  title={StaticContent.NoPermissionMessage}
                  text={StaticContent.ContactAdministratorMessage}
                />
              ) : (
                <RoutesMapper allowedRoutes={allowedRoutes} />
              )}
            </main>
          </div>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowedRoutes, handleSidebarCollapsed, userError]
  );
});
