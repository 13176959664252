import React, { useState, ReactNode } from 'react';
import {
  Dropdown as RsDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownProps
} from 'reactstrap';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import styles from './Dropdown.module.scss';

export const dropdownTestId = 'dropdownComponent';

interface OwnProps {
  children: ReactNode;
}

type Props = DropdownProps & OwnProps;

export const Dropdown = (props: Props) => {
  const { children, className, ...rest } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const cssClasses = [styles.wrapper, className];

  return (
    <RsDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      data-testid={dropdownTestId}
      className={clsx(cssClasses)}
      {...rest}
    >
      <DropdownToggle className={styles.btnLink} color="link">
        <FontAwesomeIcon
          icon={dropdownOpen ? faAngleUp : faAngleDown}
          size="lg"
        />
      </DropdownToggle>
      <DropdownMenu className={styles.menu}>{children}</DropdownMenu>
    </RsDropdown>
  );
};
