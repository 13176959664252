import { createContext, Dispatch, useContext } from 'react';

import { GlobalState, initialState } from './global-reducer';
import { Action } from '../Action';
import { GlobalThunksContextValue } from './global-thunks';

export const GlobalStateContext = createContext<GlobalState>(initialState);
export const GlobalDispatchContext = createContext<Dispatch<Action>>(() => {});
export const GlobalThunksContext = createContext<GlobalThunksContextValue>(
  {} as GlobalThunksContextValue
);

export const useGlobalStateContext = (): GlobalState =>
  useContext(GlobalStateContext);

export const useGlobalDispatchContext = (): Dispatch<Action> =>
  useContext(GlobalDispatchContext);

export const useGlobalThunksContext = (): GlobalThunksContextValue =>
  useContext(GlobalThunksContext);
