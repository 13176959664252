import React from 'react';
import { StylesProvider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './AllowedRoutesSkeleton.module.scss';

function SkeletonItem() {
  return (
    <div className={styles.item}>
      <Skeleton
        variant="rect"
        animation={false}
        classes={{ root: styles.root, rect: styles.rect }}
      />
      <Skeleton
        variant="text"
        animation={false}
        classes={{ root: styles.root, text: styles.text }}
      />
    </div>
  );
}

export const numberOfItems = 6;

export function AllowedRoutesSkeleton() {
  return (
    <StylesProvider injectFirst>
      {new Array(numberOfItems).fill(null).map((item, index) => (
        <SkeletonItem key={index} />
      ))}
    </StylesProvider>
  );
}
