import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import { NotFound } from '../../../not-found/NotFound';
import { GlobalDeps } from '../../../../GlobalDeps';
import { SessionStorageKeys } from '../../../../config/storage-config';
import { AllowedRoute } from './getAllowedRoutes';
import { RoutePath } from '../../../../config/route-config';

interface Props extends RouteComponentProps {
  allowedRoutes: AllowedRoute[];
}

export const routesTestId = 'routesTestId';

export const Routes = withRouter((props: Props) => {
  const { allowedRoutes, history } = props;

  const [shouldDisplayRoutes, displayRoutes] = useState<boolean>(false);

  const getInitialPathBeforeAuth = (): string | undefined =>
    GlobalDeps.storageService.getItem<string | undefined>(
      SessionStorageKeys.INITIAL_PATH_NAME_BEFORE_AUTH
    );

  const removeInitialPathBeforeAuth = (): void =>
    GlobalDeps.storageService.removeItem(
      SessionStorageKeys.INITIAL_PATH_NAME_BEFORE_AUTH
    );

  useEffect((): void => {
    const initialPathBeforeAuth:
      | string
      | undefined = getInitialPathBeforeAuth();

    if (initialPathBeforeAuth) {
      history.push(initialPathBeforeAuth);

      displayRoutes(true);

      removeInitialPathBeforeAuth();
    } else {
      displayRoutes(true);
    }
  }, [history]);

  return (
    <div data-testid={routesTestId}>
      {shouldDisplayRoutes && (
        <Switch>
          <Route exact path={RoutePath.Index}>
            <Redirect to={allowedRoutes[0]?.path || RoutePath.NotFound} />
          </Route>
          {allowedRoutes.map((allowedRoute: AllowedRoute) => (
            <Route
              key={allowedRoute.path}
              path={allowedRoute.path}
              component={allowedRoute.component}
            />
          ))}
          <Route path={RoutePath.NotFound} component={NotFound} />
          <Route>
            <Redirect to={RoutePath.NotFound} />
          </Route>
        </Switch>
      )}
    </div>
  );
});
