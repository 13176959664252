import React from 'react';
import { StylesProvider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './LogoSkeleton.module.scss';

export function LogoSkeleton() {
  return (
    <StylesProvider injectFirst>
      <Skeleton
        animation={false}
        variant="circle"
        classes={{ circle: styles.circle }}
      />
    </StylesProvider>
  );
}
