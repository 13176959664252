export enum RoutePath {
  Index = '/',
  ManualPO = '/manual-po',
  Configurations = '/configs',
  Payment = '/payment',
  DailyActivityPlanner = '/dap',
  Reports = '/reports',
  Settings = '/settings',
  NotFound = '/not-found',
  DOF = '/store'
}

export enum RouteName {
  ManualPO = 'Manual Purchase Order',
  Configurations = 'Configurations',
  Payment = 'Payment',
  DailyActivityPlanner = 'Daily Activity Planner',
  Reports = 'Reports',
  Settings = 'Settings',
  HelpCenter = 'Help Center',
  DOF = 'Send Digital Order Form'
}
