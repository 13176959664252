import React, { useMemo } from 'react';

import { useAuthContext } from '../../context/auth/auth-context';
import { GlobalContextProvider } from '../../context/global/GlobalContextProvider';
import { UserFetcher } from './UserFetcher';
import { Authenticated } from './authenticated/Authenticated';
import { Unauthenticated } from './unauthenticated/Unauthenticated';

export const AuthGuard = () => {
  const { userTokenData } = useAuthContext();

  return useMemo(() => {
    if (!userTokenData) {
      return <Unauthenticated />;
    }

    return (
      <>
        <GlobalContextProvider>
          <UserFetcher />
          <Authenticated />
        </GlobalContextProvider>
      </>
    );
  }, [userTokenData]);
};
