import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faReceipt,
  faCreditCard,
  faQuestionCircle,
  faCog,
  faWrench,
  faChartLine,
  faCalendar
} from '@fortawesome/free-solid-svg-icons';

import { RouteName } from '../../../config/route-config';

export const setRouteIcon = (title: string): IconProp => {
  switch (title) {
    case RouteName.ManualPO:
      return faReceipt;
    case RouteName.Configurations:
      return faWrench;
    case RouteName.Payment:
      return faCreditCard;
    case RouteName.DailyActivityPlanner:
      return faCalendar;
    case RouteName.Reports:
      return faChartLine;
    case RouteName.Settings:
      return faCog;
    case RouteName.HelpCenter:
      return faQuestionCircle;
    case RouteName.DOF:
      return faReceipt;
    default:
      return faReceipt;
  }
};
