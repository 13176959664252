import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { Alert } from 'reactstrap';

import { RoutePath } from '../../../../config/route-config';

export const noPermissionsTestId = 'noPermissionsTestId';

interface Props {
  title: string;
  text?: string;
}

export const NoPermissions = (props: Props) => {
  const { title, text } = props;

  return (
    <div data-testid={noPermissionsTestId}>
      <Switch>
        <Route exact path={RoutePath.Index}>
          <div style={{ padding: '2rem' }}>
            <Alert color="info">
              <h6>
                <strong>{title}</strong>
              </h6>
              <p>{text}</p>
            </Alert>
          </div>
        </Route>
        <Route>
          <Redirect to={RoutePath.Index} />
        </Route>
      </Switch>
    </div>
  );
};

export const NoUser = (props: Props) => <NoPermissions {...props} />;
