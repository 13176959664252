import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Aside.module.scss';

import { AppName } from '../../../config/app-config';
import { AllowedRoute } from '../../app/authenticated/routes/getAllowedRoutes';
import { RoutePath } from '../../../config/route-config';
import { AllowedRoutes } from './allowed-routes/AllowedRoutes';
import { AllowedRoutesSkeleton } from './AllowedRoutesSkeleton/AllowedRoutesSkeleton';
import { LogoSkeleton } from './LogoSkeleton/LogoSkeleton';

import { ReactComponent as Logo } from '../../../assets/images/Logo.svg';

export const asideTestId = 'asideTestId';

export interface AsideProps {
  readonly allowedRoutes?: AllowedRoute[];
  isCollapsed?: boolean;
  handleSidebarCollapsed?: () => void;
}

export const Aside = ({
  allowedRoutes,
  handleSidebarCollapsed,
  isCollapsed
}: AsideProps) => {
  return (
    <div className={clsx(isCollapsed && styles.collapsed)}>
      <aside className={styles.aside} data-testid={asideTestId}>
        <Link to={RoutePath.Index} className={styles.header}>
          {allowedRoutes ? (
            <>
              <Logo />
              <span className={styles.title}>{AppName.RacPadNoSpace}</span>
            </>
          ) : (
            <LogoSkeleton />
          )}
        </Link>
        {allowedRoutes ? (
          <AllowedRoutes allowedRoutes={allowedRoutes} />
        ) : (
          <AllowedRoutesSkeleton />
        )}

        <div className={styles.footer}>
          <div className={styles.collapseIcon} onClick={handleSidebarCollapsed}>
            {!isCollapsed ? (
              <FontAwesomeIcon icon={faAngleDoubleLeft} color="white" />
            ) : (
              <FontAwesomeIcon icon={faAngleDoubleRight} color="white" />
            )}
          </div>
        </div>
      </aside>
    </div>
  );
};
