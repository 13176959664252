import React, { useEffect } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './AllowedRoutes.module.scss';

import { AllowedRoute } from '../../../app/authenticated/routes/getAllowedRoutes';
import { setRouteIcon } from '../setRouteIcon';
import { RouteName, RoutePath } from '../../../../config/route-config';
import { AppName } from '../../../../config/app-config';

interface AllowedRoutesProps extends RouteComponentProps {
  readonly allowedRoutes: AllowedRoute[];
}

export const allowedRoutesTestId = 'allowedRoutesTestId';

export const AllowedRoutes = withRouter((props: AllowedRoutesProps) => {
  const { allowedRoutes, location } = props;
  const { pathname } = location;

  const title = () => {
    if (pathname.startsWith(RoutePath.ManualPO)) {
      return RouteName.ManualPO;
    }

    if (pathname.startsWith(RoutePath.Configurations)) {
      return RouteName.Configurations;
    }

    if (pathname.startsWith(RoutePath.Payment)) {
      return RouteName.Payment;
    }

    if (pathname.startsWith(RoutePath.DailyActivityPlanner)) {
      return RouteName.DailyActivityPlanner;
    }

    if (pathname.startsWith(RoutePath.Reports)) {
      return RouteName.Reports;
    }

    if (pathname.startsWith(RoutePath.DOF)) {
      return RouteName.DOF;
    }

    return <>{AppName.RacPad}</>;
  };

  useEffect(() => {
    document.title = `${title()} - RACPAD`;
  });

  return (
    <ul className={styles.nav} data-testid={allowedRoutesTestId}>
      {allowedRoutes.map((allowedRoute: AllowedRoute) => (
        <li key={allowedRoute.name}>
          <NavLink
            className={styles.item}
            to={allowedRoute.path}
            activeClassName={styles.active}
            title={allowedRoute.name}
          >
            <FontAwesomeIcon icon={setRouteIcon(allowedRoute.name)} />
            <span>{allowedRoute.name}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  );
});
