import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import styles from './Microfrontends.module.scss';

import config from '../../config/app-config';
import { MicroFrontend } from './MicroFrontend';

export const dailyActivityPlannerTestId = 'dailyActivityPlannerTestId';

export const DailyActivityPlanner = ({ history }: RouteComponentProps) => (
  <div className={styles.dap} data-testid={dailyActivityPlannerTestId}>
    <MicroFrontend
      config={config.microfrontends.dailyActivityPlanner}
      history={history}
    />
  </div>
);
