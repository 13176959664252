import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../config/app-config';
import { MicroFrontend } from './MicroFrontend';

export const manualPOTestId = 'manualPOTestId';

export const ManualPO = ({ history }: RouteComponentProps) => (
  <div data-testid={manualPOTestId}>
    <MicroFrontend config={config.microfrontends.manualPO} history={history} />
  </div>
);
