import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../config/app-config';
import { MicroFrontend } from './MicroFrontend';

export const storeTestId = 'storeTestId';

export const Store = ({ history }: RouteComponentProps) => (
  <div data-testid={storeTestId}>
    <MicroFrontend config={config.microfrontends.store} history={history} />
  </div>
);
