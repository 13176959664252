import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../config/app-config';
import { MicroFrontend } from './MicroFrontend';

export const reportsTestId = 'reportsTestId';

export const Reports = ({ history }: RouteComponentProps) => (
  <div data-testid={reportsTestId}>
    <MicroFrontend config={config.microfrontends.reports} history={history} />
  </div>
);
