import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Reactstrap from 'reactstrap';

import './bootstrap';

import { GlobalDeps } from './GlobalDeps';
import { StorageService } from './services/StorageService';
import { App } from './components/app/App';

import 'normalize.css';
import 'bootstrap/scss/bootstrap.scss';
import 'font-awesome/css/font-awesome.css';
import './index.scss';

window.React = React;
window.ReactDOM = ReactDOM;
window.Reactstrap = Reactstrap;
window.isRenderedByContainer = true;

GlobalDeps.initialize({
  storageService: new StorageService()
});

ReactDOM.render(<App />, document.getElementById('root'));
