import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import clsx from 'clsx';

import {
  ActionTitle,
  StaticContent
} from '../../../config/static-content-config';
import { useGlobalStateContext } from '../../../context/global/global-context';
import { useAuthContext } from '../../../context/auth/auth-context';
import { Dropdown } from '../../common/dropdown/Dropdown';
import { Title } from './Title';
import { User } from '../../../domain/User/User';

import styles from './Header.module.scss';

export const headerTestId = 'headerTestId';

interface Props extends RouteComponentProps {
  isCollapsed?: boolean;
}

const UserName = ({ user }: { user?: User }) => (
  <>{`${user?.firstName || ''} ${user?.lastName || ''}`}</>
);

export const Header = withRouter((props: Props) => {
  const { location, isCollapsed } = props;

  const {
    currentUser: { user }
  } = useGlobalStateContext();

  const { logout } = useAuthContext();

  return (
    <header
      className={clsx([styles.header, isCollapsed && styles.lessPadding])}
      data-testid={headerTestId}
    >
      <div className={styles.left}>
        <h1 className={styles.title}>
          <Title pathname={location.pathname} />
        </h1>
      </div>
      <div className={styles.right}>
        <p className={styles.welcome}>
          {StaticContent.Hello},{' '}
          <strong>
            <UserName user={user} />
          </strong>
        </p>

        <Dropdown>
          <Button
            onClick={logout}
            color="link"
            block
            className={styles.signOutBtn}
          >
            {ActionTitle.SignOut}
          </Button>
        </Dropdown>
      </div>
    </header>
  );
});
