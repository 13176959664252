import { Storable } from './services/Storable';

interface Deps {
  storageService: Storable;
}

export class GlobalDeps {
  static storageService: Storable;

  static initialize(deps: Deps) {
    GlobalDeps.storageService = deps.storageService;
  }
}
