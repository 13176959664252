import React, { useCallback, useMemo } from 'react';

import { AppName } from '../../../config/app-config';
import { useAuthContext } from '../../../context/auth/auth-context';

import styles from './Unauthenticated.module.scss';

export const unauthenticatedAppTestId = 'unauthenticatedAppTestId';

export const Unauthenticated = () => {
  const { login, error } = useAuthContext();

  const handleClick = useCallback(() => {
    if (login) {
      login();
    }
  }, [login]);

  return useMemo(
    () => (
      <div className={styles.centered} data-testid={unauthenticatedAppTestId}>
        <h1>{AppName.RacPad}</h1>
        <div className={styles.note}>
          {!error ? (
            <span>Use your Rent-A-Center credentials to sign in</span>
          ) : (
            <span
              className={styles.error}
            >{`The following error occured: ${error}`}</span>
          )}
        </div>
        <button
          className={styles.signin}
          onClick={handleClick}
          data-testid="signin"
        >
          Sign in with Single Sign-On
        </button>
      </div>
    ),
    [error, handleClick]
  );
};
