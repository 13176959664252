import React from 'react';
import { Spinner } from 'reactstrap';

import { Routes } from './Routes';
import { AllowedRoute } from './getAllowedRoutes';
import { NoPermissions } from './NoPermissions';
import { StaticContent } from '../../../../config/static-content-config';

import styles from './RoutesMapper.module.scss';

export const spinnerTestId = 'spinnerTestId';

interface Props {
  allowedRoutes?: AllowedRoute[];
}

export const RoutesMapper = ({ allowedRoutes }: Props) => {
  if (!allowedRoutes) {
    return (
      <div className={styles.progress}>
        <Spinner color="info" data-testid={spinnerTestId} />
      </div>
    );
  }

  if (allowedRoutes.length > 0) {
    return <Routes allowedRoutes={allowedRoutes} />;
  }

  return (
    <NoPermissions
      title={StaticContent.NoProductMessage}
      text={StaticContent.ContactSupportMessage}
    />
  );
};
