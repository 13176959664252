import { isObject } from 'lodash';

import { GenericObject } from '../utils/GenericObject';
import { Storable } from './Storable';

export class StorageService implements Storable {
  setItem(
    storageKey: string,
    value: GenericObject | string | number,
    isPersistent = true
  ) {
    const storage = isPersistent ? localStorage : sessionStorage;

    isObject(value)
      ? storage.setItem(storageKey, JSON.stringify(value))
      : storage.setItem(storageKey, String(value));
  }

  getItem<T>(storageKey: string): T | undefined {
    const localStorageItem = localStorage.getItem(storageKey);

    if (localStorageItem) {
      return this.getValue<T>(localStorageItem) as T;
    }

    const sessionStorageItem = sessionStorage.getItem(storageKey);

    if (sessionStorageItem) {
      return this.getValue<T>(sessionStorageItem) as T;
    }
  }

  removeItem(storageKey: string) {
    localStorage.removeItem(storageKey);
    sessionStorage.removeItem(storageKey);
  }

  clear() {
    localStorage.clear();
    sessionStorage.clear();
  }

  hasItem(storageKey: string) {
    return Boolean(this.getItem(storageKey));
  }

  private getValue<T>(value: string) {
    let valueResult;

    try {
      valueResult = JSON.parse(value);
    } catch (e) {
      valueResult = value;
    }

    return valueResult;
  }
}
