import { createContext, useContext } from 'react';

import { GenericObject } from '../../utils/GenericObject';

export interface AuthContextValue {
  userTokenData?: GenericObject;
  error?: string;
  login?: () => void;
  logout?: () => void;
}

export const AuthContext = createContext<AuthContextValue>({});

export const useAuthContext = (): AuthContextValue => useContext(AuthContext);
