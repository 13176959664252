import { Action } from '../../Action';
import { User } from '../../../domain/User/User';
import { GenericObject } from '../../../utils/GenericObject';

export enum UserActionType {
  GET_USER = 'GET_USER',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR'
}

export interface GetUserAction extends Action {
  type: UserActionType.GET_USER;
  payload: boolean;
}

export const getUserAction = (isPending: boolean): GetUserAction => ({
  type: UserActionType.GET_USER,
  payload: isPending
});

export interface GetUserSuccessAction extends Action {
  type: UserActionType.GET_USER_SUCCESS;
  payload: {
    user: User;
  };
}

export const getUserSuccessAction = (user: User): GetUserSuccessAction => ({
  type: UserActionType.GET_USER_SUCCESS,
  payload: { user }
});

export interface GetUserErrorAction extends Action {
  type: UserActionType.GET_USER_ERROR;
  payload?: GenericObject;
}

export const getUserErrorAction = (
  error: GenericObject | undefined
): GetUserErrorAction => ({
  type: UserActionType.GET_USER_ERROR,
  payload: error
});

export type CurrentuserAction =
  | GetUserAction
  | GetUserSuccessAction
  | GetUserErrorAction;
