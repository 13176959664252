import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { NavMenuPermission } from '../../../../domain/Permission/NavMenuPermission';
import {
  canAccessConfigurations,
  canAccessDailyActivityPlanner,
  canAccessManualPO,
  canAccessPayment,
  canAccessReports,
  canAccessDOF
} from '../../../../domain/Permission/permissions';
import { Payment } from '../../../microfrontends/Payment';
import { ManualPO } from '../../../microfrontends/ManualPO';
import { Configurations } from '../../../microfrontends/Configurations';
import { DailyActivityPlanner } from '../../../microfrontends/DailyActivityPlanner';
import { Reports } from '../../../microfrontends/Reports';
import { Store } from '../../../microfrontends/Store';
import { RouteName, RoutePath } from '../../../../config/route-config';

export interface AllowedRoute {
  readonly name: RouteName;
  readonly path: string;
  readonly component: FC<RouteComponentProps>;
  readonly displayOrder: number;
}

export const getAllowedRoutes = (
  actions: NavMenuPermission[]
): AllowedRoute[] =>
  actions
    .reduce((configs: AllowedRoute[], action: NavMenuPermission) => {
      if (canAccessPayment(action)) {
        return [
          ...configs,
          {
            name: RouteName.Payment,
            path: RoutePath.Payment,
            component: Payment,
            displayOrder: 1
          }
        ];
      }

      if (canAccessManualPO(action)) {
        return [
          ...configs,
          {
            name: RouteName.ManualPO,
            path: RoutePath.ManualPO,
            component: ManualPO,
            displayOrder: 2
          }
        ];
      }

      if (canAccessConfigurations(action)) {
        return [
          ...configs,
          {
            name: RouteName.Configurations,
            path: RoutePath.Configurations,
            component: Configurations,
            displayOrder: 3
          }
        ];
      }

      if (canAccessDailyActivityPlanner(action)) {
        return [
          ...configs,
          {
            name: RouteName.DailyActivityPlanner,
            path: RoutePath.DailyActivityPlanner,
            component: DailyActivityPlanner,
            displayOrder: 4
          }
        ];
      }

      if (canAccessReports(action)) {
        return [
          ...configs,
          {
            name: RouteName.Reports,
            path: RoutePath.Reports,
            component: Reports,
            displayOrder: 5
          }
        ];
      }

      if (canAccessDOF(action)) {
        return [
          ...configs,
          {
            name: RouteName.DOF,
            path: RoutePath.DOF,
            component: Store,
            displayOrder: 6
          }
        ];
      }

      return configs;
    }, [])
    .sort((a: AllowedRoute, b: AllowedRoute) =>
      a.displayOrder > b.displayOrder ? 1 : -1
    );
