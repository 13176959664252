import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import { AuthContextProvider } from '../../context/auth/AuthContextProvider';
import { AuthGuard } from './AuthGuard';

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

export const appTestId = 'appTestId';

export const App = () => (
  <div data-testid={appTestId}>
    <Router history={history}>
      <AuthContextProvider>
        <AuthGuard />
      </AuthContextProvider>
    </Router>
  </div>
);
