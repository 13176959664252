import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import config from '../../config/app-config';
import { MicroFrontend } from './MicroFrontend';

export const configurationsTestId = 'configurationsTestId';

export const Configurations = ({ history }: RouteComponentProps) => (
  <div data-testid={configurationsTestId}>
    <MicroFrontend
      config={config.microfrontends.configurations}
      history={history}
    />
  </div>
);
