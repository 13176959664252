import React, { Component } from 'react';
import { History } from 'history';

export const microFrontendTestId = 'microFrontendTestId';

interface Config {
  name: string;
  url: string;
}

interface Props {
  config: Config;
  history: History;
}

export class MicroFrontend extends Component<Props> {
  componentDidMount() {
    const {
      config: { name, url }
    } = this.props;

    const scriptId = `micro-frontend-script-${name}`;

    if (document.getElementById(scriptId)) {
      this.renderMicroFrontend();

      return;
    }

    fetch(`${url}/asset-manifest.json`)
      .then(res => res.json())
      .then(manifest => {
        const { host, protocol } = new URL(url);

        const cssPath = manifest.files['main.css'];

        if (cssPath) {
          const head = document.getElementsByTagName('head')[0];
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = `${protocol}//${host}${cssPath}`;
          link.media = 'all';
          head.appendChild(link);
        }

        const jsPath = manifest.files['main.js'];

        if (jsPath) {
          const script = document.createElement('script');
          script.id = scriptId;
          script.crossOrigin = '';
          script.src = `${protocol}//${host}${jsPath}`;
          script.onload = this.renderMicroFrontend;

          if (document.body) {
            document.body.appendChild(script);
          }
        }
      });
  }

  renderMicroFrontend = () => {
    const {
      config: { name },
      history
    } = this.props;

    const renderFn = window[`render${name}`];

    if (renderFn) {
      renderFn(`${name}-container`, history);
    }
  };

  render() {
    const {
      config: { name }
    } = this.props;

    return <div id={`${name}-container`} data-testid={microFrontendTestId} />;
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    const {
      config: { name }
    } = this.props;

    const unmountFn = window[`unmount${name}`];

    if (unmountFn) {
      unmountFn(`${name}-container`);
    }
  }
}
