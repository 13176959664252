import { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  verticalAxis: number;
}

export const ScrollTo = withRouter(({ history, verticalAxis }: Props) => {
  useEffect(() => {
    const historyListen = history.listen(() => {
      window.scrollTo(0, verticalAxis);
    });

    return () => {
      historyListen();
    };
  }, [history, verticalAxis]);

  return null;
});
