import { NavMenuPermission } from './NavMenuPermission';

export const canAccessManualPO = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_MANUAL_PO;

export const canAccessConfigurations = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_CONFIGURATIONS;

export const canAccessPayment = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_PAYMENT;

export const canAccessReports = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_REPORTS;

export const canAccessDailyActivityPlanner = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_DAILY_ACTIVITY_PLANNER;

export const canAccessDOF = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_DOF;
