import React from 'react';

import styles from './NotFound.module.scss';

export const notFoundTestId = 'notFoundComponent';

export const NotFound = () => (
  <div data-testid={notFoundTestId} className={styles.notFoundContainer}>
    <p>Sorry... nothing here.</p>
  </div>
);
